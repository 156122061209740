import { types } from "mobx-state-tree";

const parts = {
  skinTone: ["brown", "light", "dark", "black", "red", "yellow"],
  body: ["chest", "breasts"],
  accessory: ["none", "roundGlasses", "tinyGlasses", "shades"],
  mouth: ["serious", "grin", "openSmile", "lips", "open", "tongue"],
  facialHair: ["none", "stubble", "mediumBeard"],
  clothing: ["shirt", "dressShirt", "vneck", "dress"],
  hat: ["none", "beanie", "turban"],
  hatColor: ["white", "blue", "black", "red", "green"],
  hairColor: ["blonde", "orange", "black", "white", "brown", "blue", "pink"],
  clothingColor: ["white", "blue", "black", "green", "red"],
  eyes: ["normal", "squint", "wink", "leftTwitch", "dizzy", "heart"],
  hair: [
    "none",
    "long",
    "bun",
    "short",
    "pixie",
    "balding",
    "buzz",
    "afro",
    "bob",
  ],
};
// -------------------------------------------

const Avatar = types
  .model({
    skinTone: types.enumeration("skinTone", [
      "black",
      "brown",
      "red",
      "yellow",
      "light",
      "dark",
    ]),
    body: types.enumeration("body", ["chest", "breasts"]),
    hair: types.enumeration("hair", [
      "none",
      "long",
      "bun",
      "short",
      "pixie",
      "balding",
      "buzz",
      "afro",
      "bob",
    ]),
    hairColor: types.enumeration("hairColor", [
      "blonde",
      "orange",
      "black",
      "white",
      "brown",
      "blue",
      "pink",
    ]),
    clothing: types.enumeration("clothing", [
      "shirt",
      "dressShirt",
      "vneck",
      "dress",
    ]),
    clothingColor: types.enumeration("clothingColor", [
      "black",
      "blue",
      "green",
      "red",
      "white",
    ]),
    hat: types.enumeration("hat", [
      "none",
      "beanie",
      "turban",
      "none2",
      "none3",
      "none4",
      "none5",
    ]),
    hatColor: types.enumeration("hatColor", [
      "black",
      "red",
      "white",
      "blue",
      "green",
    ]),
    accessory: types.enumeration("accessory", [
      "none",
      "roundGlasses",
      "tinyGlasses",
      "shades",
    ]),
    eyes: types.enumeration("eyes", [
      "content",
      "normal",
      "squint",
      "wink",
      "leftTwitch",
      "dizzy",
      "heart",
      "happy",
      "simple",
    ]),
    facialHair: types.enumeration("facialHair", [
      "none",
      "stubble",
      "mediumBeard",
    ]),
    mouth: types.enumeration("mouth", [
      "serious",
      "grin",
      "openSmile",
      "lips",
      "open",
      "tongue",
    ]),
    name: types.string,
    circleColor: types.enumeration("circleColor", ["blue"]),
    eyebrows: types.enumeration("eyebrows", [
      "serious",
      "angry",
      "raised",
      "leftLowered",
      "concerned",
    ]),
    graphic: types.enumeration("graphic", [
      "none",
      "redwood",
      "gatsby",
      "vue",
      "react",
      "graphQL",
    ]),
    faceMask: types.boolean,
    lashes: types.boolean,
    lipColor: types.enumeration("lipColor", [
      "red",
      "green",
      "pink",
      "purple",
      "turqoise",
    ]),
  })
  .actions((self) => ({
    setProperty(property: any) {
      self[property] =
        parts[property][
          (parts[property].indexOf(self[property]) + 1) % parts[property].length
        ];
    },
    setName(name: string) {
      self.name = name;
    },
  }));

export const avatar = Avatar.create({
  skinTone: "light",
  eyes: "normal",
  mouth: "open",
  facialHair: "none",
  body: "chest",
  accessory: "none",
  hairColor: "black",
  hair: "short",
  clothingColor: "white",
  clothing: "shirt",
  hat: "none",
  hatColor: "white",
  name: "",
  circleColor: "blue",
  eyebrows: "angry",
  graphic: "none",
  faceMask: false,
  lashes: false,
  lipColor: "red",
});

// -------------------------------------------

const Toast = types
  .model({
    isVisible: types.boolean,
  })
  .actions((self) => ({
    setVisible(status: boolean) {
      self.isVisible = status;
    },
  }));

export const toast = Toast.create({
  isVisible: false,
});

// -------------------------------------------
const Auth = types
  .model({
    isRegistred: types.boolean,
    room: types.optional(types.string, ""),
  })
  .actions((self) => ({
    setRegistred(status: boolean) {
      self.isRegistred = status;
    },
    setRoom(status: string) {
      self.room = status;
    },
  }));

export const auth = Auth.create({
  isRegistred: false,
  room: "",
});

// -------------------------------------------

const Player = types
  .model({
    name: types.string,
    avatar: types.optional(types.string, ""),
  })
  .actions((self) => ({
    setName(status: string) {
      self.name = status;
    },
    setAvatar(status: string) {
      self.avatar = status;
    },
  }));

export const player = Player.create({
  name: "",
  avatar: "",
});

const Room = types
  .model({
    phase: types.integer,
    room: types.optional(types.string, ""),
    playerList: types.optional(types.array(Player), []),
    loading: types.optional(types.boolean, false),
  })
  .actions((self) => ({
    setPhase(status: number) {
      self.phase = status;
    },
    setRoom(status: string) {
      self.room = status;
    },
    updatePlayers(status: any) {
      self.playerList.push(status);
    },
    setPlayers(status: any) {
      self.playerList = status;
    },
    setLoading(status: boolean) {
      self.loading = status;
    },
  }));

export const room = Room.create({
  phase: 1,
  room: "",
  loading: false,
});
