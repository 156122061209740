import React from "react";
import GithubIcon from "../../assets/icons/GithubIcon";
import InfoIcon from "../../assets/icons/InfoIcon";
import MailIcon from "../../assets/icons/MailIcon";
import tw, { styled } from "twin.macro";

const Container = styled.div`
  ${tw`relative mt-32 bg-deep-purple-accent-400`}
`;

const Buttons = styled.div`
  ${tw`flex justify-center h-28 items-center mt-4 space-x-4 sm:mt-0`}
`;

const Divider = () => (
  <svg
    className="absolute top-0 w-full h-6 -mt-5 sm:-mt-10 sm:h-16 text-deep-purple-accent-400"
    preserveAspectRatio="none"
    viewBox="0 0 1440 54"
  >
    <path
      fill="currentColor"
      d="M0 22L120 16.7C240 11 480 1.00001 720 0.700012C960 1.00001 1200 11 1320 16.7L1440 22V54H1320C1200 54 960 54 720 54C480 54 240 54 120 54H0V22Z"
    />
  </svg>
);

const Footer = () => {
  return (
    <Container>
      <Divider />
      <Buttons>
        <GithubIcon />
        <MailIcon />
        <InfoIcon />
      </Buttons>
    </Container>
  );
};

export default Footer;
