import React from "react";

const SubmitIcon = () => {
  return (
    <svg
      width="22"
      height="21"
      viewBox="0 0 22 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.1796 9.44334L1.63988 0.111016C1.44313 0.0170517 1.22414 -0.0183381 1.00836 0.00895653C0.792579 0.0362512 0.588853 0.12511 0.420839 0.265215C0.252826 0.40532 0.127419 0.590922 0.0591802 0.80047C-0.00905807 1.01002 -0.0173278 1.23491 0.0353316 1.44904L1.4284 7.10559L10.3454 10.4991L1.4284 13.8925L0.0353316 19.5491C-0.0183186 19.7634 -0.0107391 19.9887 0.0571836 20.1988C0.125106 20.4089 0.250564 20.595 0.41888 20.7353C0.587197 20.8757 0.791413 20.9645 1.00764 20.9914C1.22387 21.0182 1.44317 20.9821 1.63988 20.8871L21.1796 11.5548C21.377 11.4606 21.544 11.3113 21.6609 11.1243C21.7779 10.9374 21.84 10.7205 21.84 10.4991C21.84 10.2776 21.7779 10.0607 21.6609 9.87379C21.544 9.68684 21.377 9.53754 21.1796 9.44334V9.44334Z"
        fill="white"
      />
    </svg>
  );
};

export default SubmitIcon;
