import React from "react";

const UndoIcon = () => {
  return (
    <svg
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.5002 23C9.73334 23 8.07127 22.6215 6.51401 21.8646C4.95675 21.1077 3.64655 20.0623 2.58342 18.7284L7.09798 15.1987C7.63703 15.8432 8.28839 16.3453 9.05204 16.705C9.8157 17.0647 10.6318 17.2446 11.5002 17.2446C13.0874 17.2446 14.4426 16.6862 15.5656 15.5696C16.6886 14.453 17.2501 13.1003 17.2501 11.5116C17.2501 9.92285 16.6886 8.56642 15.5656 7.44231C14.4426 6.3182 13.0874 5.75615 11.5002 5.75615C10.1975 5.75615 9.02958 6.16083 7.9964 6.97019L11.5227 10.4774C11.5526 11.1819 11.328 11.5266 10.8489 11.5116H0.966269C0.711716 11.4966 0.494598 11.3542 0.314915 11.0844C0.135231 10.8146 0.0453889 10.5224 0.0453889 10.2076L0.000467927 0.675169C-0.0145057 0.210537 0.329888 -0.0142856 1.03365 0.000702569L3.90859 2.87843C4.94177 1.96415 6.10972 1.25596 7.41243 0.753856C8.71513 0.251754 10.0777 0.000702569 11.5002 0.000702569C13.0575 0.000702569 14.5474 0.304212 15.9699 0.911232C17.3924 1.51825 18.6165 2.3351 19.6422 3.36179C20.6679 4.38848 21.4839 5.61001 22.0903 7.02639C22.6968 8.44277 23 9.93409 23 11.5004C23 13.0666 22.6968 14.5579 22.0903 15.9743C21.4839 17.3907 20.6679 18.6122 19.6422 19.6389C18.6165 20.6656 17.3924 21.4825 15.9699 22.0895C14.5474 22.6965 13.0575 23 11.5002 23Z"
        fill="#666FE4"
      />
    </svg>
  );
};

export default UndoIcon;
