import { observer } from "mobx-react";
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Toast from "./components/common/Toast";
import Home from "./pages/Home";
import Lobby from "./pages/Lobby";
import Room from "./pages/Room";
import { room, toast } from "./store/Store";
import { GlobalStyles } from "twin.macro";
import Spinner from "./components/common/Spinner";
import Boards from "./pages/boards/Boards";

const App = observer(() => {
  return (
    <Router>
      <GlobalStyles />
      {room.loading && <Spinner />}
      {toast.isVisible && (
        <Toast
          shortText="Confirmation successful"
          longText="Your choices have been saved."
          onClick={() => toast.setVisible(false)}
        />
      )}

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/lobby" element={<Lobby />} />
        <Route path="/boards" element={<Boards />} />
        <Route path="/lobby/:id" element={<Room />} />
      </Routes>
    </Router>
  );
});

export default App;
